import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { PublicKey } from '@solana/web3.js';
import { useNavigate } from 'react-router-dom';
import { FiLogOut, FiLink } from 'react-icons/fi'; // Importing icons
import { FaCoins, FaWallet } from 'react-icons/fa'; // Importing coin icon

const WalletManagementPage = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [enteredAddress, setEnteredAddress] = useState('');
  const [earnedTokens, setEarnedTokens] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch wallet address and earned tokens from Firestore when the page loads
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;

      if (!user) {
        console.error("User is not logged in. Redirecting to login.");
        navigate('/login');
        return;
      }

      try {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setWalletAddress(userData.walletAddress || null);
          setEarnedTokens(userData.earnedTokens || 0);
        }
      } catch (error) {
        console.error("Error fetching wallet data:", error);
        setError("Failed to load wallet data.");
      }

      setLoading(false);
    };

    fetchUserData();
  }, [navigate]);

  // Handle connecting Phantom Wallet
  const connectWallet = async () => {
    try {
      const provider = window.solana;
      if (provider) {
        await provider.connect();
        const wallet = new PublicKey(provider.publicKey.toString());
        setWalletAddress(wallet.toString());

        // Save the wallet address in Firestore for the current user
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          await setDoc(userDocRef, { walletAddress: wallet.toString() }, { merge: true });
        }
      } else {
        setError("Solana wallet not found. Please install a wallet like Phantom.");
      }
    } catch (err) {
      console.error("Error connecting wallet:", err);
      setError("Failed to connect to wallet: " + err.message);
    }
  };

  // Handle manual wallet address entry
  const handleManualWalletEntry = async () => {
    if (!enteredAddress) {
      setError('Please enter a valid wallet address.');
      return;
    }

    try {
      const wallet = new PublicKey(enteredAddress); // Validate entered address
      setWalletAddress(wallet.toString());

      // Save the wallet address in Firestore for the current user
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        await setDoc(userDocRef, { walletAddress: wallet.toString() }, { merge: true });
      }
      setError('');
    } catch (err) {
      console.error("Invalid wallet address:", err);
      setError('Invalid wallet address. Please enter a valid Solana address.');
    }
  };

  const disconnectWallet = async () => {
    try {
      // Remove wallet address from Firestore
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        await setDoc(userDocRef, { walletAddress: null }, { merge: true });
      }
      setWalletAddress(null);
      setEnteredAddress('');
      setError('');
    } catch (err) {
      console.error("Error disconnecting wallet:", err);
      setError("Failed to disconnect wallet.");
    }
  };

  if (loading) return <div style={loadingStyle}>Loading...</div>;

  return (
    <div style={pageStyle}>
      <div style={cardStyle}>
        <h1 style={headingStyle}>Wallet Management</h1>
        <p style={descriptionStyle}>
          Manage your Solana wallet to receive tokens earned through optimizing your network bandwidth.
        </p>

        {walletAddress ? (
          <div style={walletInfoContainerStyle}>
            <div style={walletInfoStyle}>
              <FaWallet style={walletIconStyle} />
              <p>
                <strong>Connected Wallet:</strong> <span>{walletAddress}</span>
              </p>
            </div>

            {/* Display earned tokens */}
            <div style={tokenInfoStyle}>
              <FaCoins style={tokenIconStyle} />
              <p>
                <strong>Earned Tokens:</strong> {earnedTokens} WIBIT
              </p>
            </div>

            <button onClick={disconnectWallet} style={disconnectButtonStyle}>
              <FiLogOut style={{ marginRight: '8px' }} />
              Disconnect Wallet
            </button>
          </div>
        ) : (
          <div style={connectContainerStyle}>
            <button onClick={connectWallet} style={connectButtonStyle}>
              <FiLink style={{ marginRight: '8px' }} />
              Connect Phantom Wallet
            </button>

            <p style={orStyle}>— OR —</p>

            <div style={manualEntryStyle}>
              <input
                type="text"
                value={enteredAddress}
                onChange={(e) => setEnteredAddress(e.target.value)}
                placeholder="Enter your wallet address"
                style={inputStyle}
              />
              <button onClick={handleManualWalletEntry} style={manualButtonStyle}>
                Save Wallet Address
              </button>
            </div>
          </div>
        )}

        {error && <p style={errorStyle}>{error}</p>}
      </div>
    </div>
  );
};

// Styles
const pageStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(135deg, #1f1c2c, #928dab)',
  color: '#fff',
  minHeight: '100vh',
  padding: '20px',
  textAlign: 'center', // Center everything for better readability
};


const cardStyle = {
  background: 'rgba(0, 0, 0, 0.7)',
  borderRadius: '15px',
  padding: '20px',
  maxWidth: '500px',
  width: '100%',
  boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
  textAlign: 'center',
};

const headingStyle = {
  fontSize: '2.5rem',
  marginBottom: '20px',
  color: '#fff',
};

const descriptionStyle = {
  fontSize: '1.2rem',
  color: '#ddd',
  marginBottom: '30px',
};

const walletInfoContainerStyle = {
  marginTop: '30px',
};

const walletInfoStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  padding: '15px',
  borderRadius: '10px',
  marginBottom: '20px',
};

const walletIconStyle = {
  fontSize: '1.5rem',
  marginRight: '10px',
  color: '#00d09c',
};

const tokenInfoStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  padding: '15px',
  borderRadius: '10px',
  marginBottom: '20px',
};

const tokenIconStyle = {
  fontSize: '1.5rem',
  marginRight: '10px',
  color: '#ffd700',
};

const errorStyle = {
  color: '#ff4d4d',
  marginTop: '20px',
};

const connectContainerStyle = {
  marginTop: '20px',
};

const connectButtonStyle = {
  padding: '15px 30px',
  fontSize: '1.1rem',
  borderRadius: '10px',
  border: 'none',
  background: 'linear-gradient(90deg, #8e2de2, #4a00e0)',
  color: '#fff',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'transform 0.3s ease',
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const disconnectButtonStyle = {
  justifyContent: 'center',  // Center content horizontally
  alignItems: 'center',    // Center content vertically
  padding: '10px 20px',
  borderRadius: '10px',
  backgroundColor: '#A855F7',
  color: '#fff',
  fontWeight: 'bold',
  fontSize: '1rem',
  cursor: 'pointer',
  border: 'none',
  transition: 'background-color 0.3s',
};

const orStyle = {
  marginTop: '30px',
  fontSize: '1.2rem',
  color: '#ccc',
};

const manualEntryStyle = {
  marginTop: '20px',
};

const inputStyle = {
  padding: '12px',
  fontSize: '1rem',
  borderRadius: '5px',
  border: 'none',
  marginRight: '10px',
  width: 'calc(100% - 150px)',
  maxWidth: '250px',
};

const manualButtonStyle = {
  padding: '12px 20px',
  fontSize: '1rem',
  borderRadius: '5px',
  border: 'none',
  background: 'linear-gradient(90deg, #00c6ff, #0072ff)',
  color: '#fff',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'transform 0.3s ease',
};

const loadingStyle = {
  fontSize: '2rem',
  color: '#fff',
  textAlign: 'center',
};

export default WalletManagementPage;