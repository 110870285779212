import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HalfCircleGauge from './HalfCircleGauge'; // Ensure the correct path
import 'react-vertical-timeline-component/style.min.css';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';

const HomePage = () => {
  const [throttleValue, setThrottleValue] = useState(0);


  useEffect(() => {
    let increment = true; // Variable to determine if the throttle is increasing or decreasing
    let throttleValue = 0;
    let cycleCount = 0; // Track how many cycles are completed
    const maxCycles = 2; // Run the animation twice (0 to 100 to 26)
    
    const animateThrottle = (speed = 100) => {
      if (increment) {
        throttleValue += 1;
        if (throttleValue >= 100) {
          increment = false; // Start decreasing after reaching 100
          cycleCount += 1; // Increment cycle count after a full up-down cycle
        }
      } else {
        throttleValue -= 1;
        if (throttleValue <= 26) {
          increment = true; // Start increasing after reaching 26
        }
      }
  
      setThrottleValue(throttleValue); // Update throttle value in state
  
      // If max cycles are reached, stop animation
      if (cycleCount >= maxCycles) return;
  
      // Adjust the speed for acceleration (decrease time between updates when speeding up)
      const newSpeed = increment ? speed * 0.95 : speed * 1.05; // Speed up when increasing, slow down when decreasing
  
      // Schedule the next update with adjusted speed for smooth acceleration/deceleration
      setTimeout(() => animateThrottle(newSpeed), newSpeed);
    };
  
    animateThrottle(); // Start the animation
  
    return () => {
      throttleValue = 0; // Clean up when component unmounts
    };
  }, []);

  return (
    <div style={pageStyle}>
      <div style={contentWrapperStyle}>
        <h1 style={headingStyle}>
          WiBit: Decentralized Bandwidth Optimization
        </h1>
        <p style={descriptionStyle}>
          Empowering decentralized Wi-Fi networks through incentivized bandwidth
          management.
        </p>

        {/* Throttle Gauge Animation Section */}
        <div style={gaugeContainerStyle}>
          <HalfCircleGauge value={throttleValue} />
          <p style={gaugeCaptionStyle}>
            Real-time Network Speed
          </p>
        </div>

        {/* Feature Cards */}
        <div id="features" style={cardContainerStyle}>
          {/* Bandwidth Control Section */}
          <div style={cardStyle} className="card">
            <div className="icon" style={iconStyle}>
              <i className="fas fa-signal"></i>
            </div>
            <h2>Bandwidth Control</h2>
            <p>Seamlessly manage your bandwidth and optimize network usage.</p>
            <Link to="/wifi-control">
              <button style={buttonStyle} className="button">
                Learn More
              </button>
            </Link>
          </div>

          {/* Wallet Integration Section */}
          <div style={cardStyle} className="card">
            <div className="icon" style={iconStyle}>
              <i className="fas fa-wallet"></i>
            </div>
            <h2>Wallet Integration</h2>
            <p>Connect and manage your Solana wallet with ease.</p>
            <Link to="/wallet-management">
              <button style={buttonStyle} className="button">
                Learn More
              </button>
            </Link>
          </div>

          {/* Token Rewards Section */}
          <div style={cardStyle} className="card">
            <div className="icon" style={iconStyle}>
              <i className="fas fa-coins"></i>
            </div>
            <h2>Token Rewards</h2>
            <p>Earn tokens by contributing to network efficiency.</p>
            <Link to="/tokenomics">
              <button style={buttonStyle} className="button">
                Learn More
              </button>
            </Link>
          </div>
        </div>

        {/* Project Overview Section */}
        <div id="overview" style={sectionStyle}>
          <h2 style={sectionHeadingStyle}>Project Overview</h2>
          <h3 style={subHeadingStyle}>The Problem</h3>
          <p style={sectionTextStyle}>
            High-traffic areas such as stadiums, airports, and city centers often experience network congestion during peak hours or large events. This leads to slower connections and reduced service quality, affecting user experience and productivity.
          </p>
          <h3 style={subHeadingStyle}>Our Solution</h3>
          <p style={sectionTextStyle}>
            WiBit is a decentralized platform designed to optimize Wi-Fi bandwidth usage in high-density areas. By incentivizing users to voluntarily reduce their bandwidth consumption during peak times, we enhance overall network performance and reliability. Users are rewarded with tokens for contributing to bandwidth management, fostering a cooperative network environment.
          </p>
        </div>

        {/* Roadmap Section */}
        <div id="roadmap" style={sectionStyle}>
          <h2 style={sectionHeadingStyle}>Roadmap</h2>
          <VerticalTimeline>
            {/* Q1 2024 */}
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              //date="Q1 2024"
              iconStyle={{
                background: '#8B5CF6',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={<i className="fas fa-code"></i>}
            >
              <h3 className="vertical-timeline-element-title">
                Platform Development and Beta Testing
              </h3>
              <p>
                Launch the platform and conduct extensive beta testing to ensure
                stability and performance.
              </p>
            </VerticalTimelineElement>
            {/* Q2 2024 */}
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              //date="Q2 2024"
              iconStyle={{
                background: '#8B5CF6',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={<i className="fas fa-wallet"></i>}
            >
              <h3 className="vertical-timeline-element-title">
                Token Launch and Wallet Integration
              </h3>
              <p>
                Introduce the WiBit token and integrate with popular wallets for
                seamless transactions.
              </p>
            </VerticalTimelineElement>
            {/* Q3 2024 */}
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              //date="Q3 2024"
              iconStyle={{
                background: '#8B5CF6',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={<i className="fas fa-handshake"></i>}
            >
              <h3 className="vertical-timeline-element-title">
                Partnerships with Major Venues
              </h3>
              <p>
                Collaborate with stadiums, airports, and public event organizers to
                expand the network.
              </p>
            </VerticalTimelineElement>
            {/* Q4 2024 */}
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              //date="Q4 2024"
              iconStyle={{
                background: '#8B5CF6',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={<i className="fas fa-globe"></i>}
            >
              <h3 className="vertical-timeline-element-title">
                Global Expansion and Network Scaling
              </h3>
              
              <p>
                Scale the network globally and enhance features based on user
                feedback.
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
        
        
        {/* Get Started Section */}
        <div id="get-started" style={sectionStyle}>
          <h2 style={sectionHeadingStyle}>Get Started</h2>
          <p style={sectionTextStyle}>
            Join us in revolutionizing network efficiency. Sign up today to participate in our beta testing phase and start earning rewards.
          </p>
          <Link to="/login">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <button style={getStartedButtonStyle}>Get Started</button>
</div>
          </Link>
        </div>

        {/* Team Section */}
        <div id="team" style={sectionStyle}>
          <h2 style={sectionHeadingStyle}>Meet the Team</h2>
          <div style={teamContainerStyle}>
            <div style={teamMemberStyle}>
              <img
                src="../ryankaelle.png"
                alt="Ryan Kaelle"
                style={teamImageStyle}
              />
              <h3 style={teamNameStyle}>Ryan Kaelle</h3>
              <p style={teamRoleStyle}>Founder & CEO</p>
            </div>
            {/* Add more team members here */}
          </div>
        </div>

        {/* Footer Section */}
        <footer style={footerStyle}>
          <div style={footerContentStyle}>
            <p>&copy; {new Date().getFullYear()} WiBit. All rights reserved.</p>
            <div style={socialIconsStyle}>
              {/* Add social media icons */}
              <a
                href="https://twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={socialIconStyle}
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://github.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={socialIconStyle}
              >
                <i className="fab fa-github"></i>
              </a>
              {/* Add more icons */}
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

// Page styling with a dark background and gradient accents
const pageStyle = {
  textAlign: 'center',
  minHeight: '100vh',
  color: '#fff',
  fontFamily: "'Roboto', sans-serif",
  backgroundColor: '#0D0D0D',
  position: 'relative',
  overflowX: 'hidden',
};


const contentWrapperStyle = {
  paddingTop: '100px',
};

const headingStyle = {
  fontSize: '3.5rem',
  marginBottom: '20px',
  background: 'linear-gradient(90deg, #A855F7, #8B5CF6)', // Gradient heading text
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '2px',
};

const getStartedButtonStyle = {
  padding: '15px 30px',
  justifyContent: 'center',
  marginTop: '20px',
  cursor: 'pointer',
  borderRadius: '10px',
  border: 'none',
  backgroundColor: '#A855F7',
  color: '#fff',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  transition: 'background-color 0.3s',
};

const descriptionStyle = {
  fontSize: '1.6rem',
  color: '#ccc',
  marginBottom: '40px',
  padding: '0 20px',
  maxWidth: '800px',
  margin: '0 auto',
};

const gaugeContainerStyle = {
  paddingTop: '50px',
  margin: '50px auto',
  textAlign: 'center',
};

const gaugeCaptionStyle = {
  color: '#fff',
  marginTop: '20px',
  fontSize: '1.1rem',
  fontStyle: 'italic',
};

// Section styling with extra margin for spacing
const sectionStyle = {
  margin: '60px auto',
  padding: '30px',
  textAlign: 'left',
  maxWidth: '800px',
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: '15px',
  backdropFilter: 'blur(10px)',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
};

const sectionHeadingStyle = {
  fontSize: '2.2rem',
  color: '#fff',
  marginBottom: '15px',
};

const sectionTextStyle = {
  fontSize: '1.3rem',
  color: '#ddd',
};

const cardContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '40px',
  marginBottom: '50px',
  flexWrap: 'wrap',
};

const cardStyle = {
  width: '260px',
  padding: '25px',
  borderRadius: '20px',
  boxShadow: '0px 12px 25px rgba(0, 0, 0, 0.3)',
  background: 'linear-gradient(145deg, #1F1F1F, #2C2C2C)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  color: '#fff',
  textAlign: 'center',
};

const iconStyle = {
  fontSize: '60px',
  marginBottom: '10px',
  color: '#A855F7',
};

const buttonStyle = {
  padding: '12px 24px',
  marginTop: '20px',
  cursor: 'pointer',
  borderRadius: '10px',
  border: 'none',
  backgroundColor: '#A855F7',
  color: '#fff',
  fontWeight: 'bold',
  transition: 'background-color 0.3s',
};


const teamContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '40px',
  flexWrap: 'wrap',
};

const teamMemberStyle = {
  textAlign: 'center',
  maxWidth: '200px',
};

const teamImageStyle = {
  width: '150px',
  height: '150px',
  borderRadius: '50%',
  objectFit: 'cover',
  objectPosition: 'top',
  marginBottom: '10px',
};

const subHeadingStyle = {
  fontSize: '1.6rem',
  color: '#A855F7',
  marginTop: '20px',
  marginBottom: '10px',
};

const teamNameStyle = {
  fontSize: '1.5rem',
  marginBottom: '5px',
};

const teamRoleStyle = {
  fontSize: '1.1rem',
  color: '#A855F7',
};

const footerStyle = {
  backgroundColor: '#1A1A1A',
  padding: '20px',
  marginTop: '60px',
};

const footerContentStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '800px',
  margin: '0 auto',
  flexWrap: 'wrap',
};

const socialIconsStyle = {
  display: 'flex',
  gap: '20px',
};

const socialIconStyle = {
  color: '#fff',
  fontSize: '1.5rem',
  transition: 'color 0.3s',
};

// Keyframes for animations and additional styles
const styles = `
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import url('https://use.fontawesome.com/releases/v5.15.3/css/all.css');


.vertical-timeline::before {
  background: #A855F7;
}

.vertical-timeline-element-content {
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
  box-shadow: 0 3px 0 #A855F7;
}

.vertical-timeline-element-content h3,
.vertical-timeline-element-content p {
  color: #fff;
}

.vertical-timeline-element-date {
  color: #A855F7 !important;
  text-align: center;
}
.card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.4);
}

.button:hover {
  background-color: #8B5CF6;
}

.nav a:hover {
  color: #A855F7;
}

.social-icon:hover {
  color: #A855F7;
}
`;

// Inject the styles into the document head
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default HomePage;